import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="Course Management">
      <section className="feature-hero">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-4 margin-top-l">
                  <h1>Course Management</h1>
                  <p className="subtitle">Lingco has all of the tools you need to successfully run a language course.</p>
                  <p className="description">Integrated gradebooks with easy-to-use exporting, reusable content and LMS
                        integrations make it painless for you to use Lingco Classroom alongside your existing tools.</p>
               </div>
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-5 col-lg-offset-1">
                  <div className="feature-img wide"><img src="/img/feature-screen-gradebook.png"
                     alt="feature-screen-gradebook" /></div>
               </div>
            </div>
         </div>
      </section>
      <section className="platform-features">
         <div className="container-fluid">
            <div className="row center-xs middle-xs margin-bottom-xl">
               <div className="col-xs-12 col-sm-10 col-lg-4 col-lg-offset-1">
                  <h3>Integrated Gradebook</h3>
                  <p className="text-larger">You can always see up-to-date grades in Lingco Classroom. These grades are
                  easily exportable via CSV in compliance with the Learning Tools Interoperability (LTI) standard,
                        making it easy to integrate with an LMS like Canvas, D2L, Moodle, or Blackboard.</p>
               </div>
               <div className="col-xs-12 col-sm-10 col-lg-3 col-lg-offset-1">
                  <div className="feature-img"><img src="/img/feature-crop-grades.png" alt="feature-crop-grades" /></div>
               </div>
               <div className="col-xs-12 col-lg-1"></div>
            </div>
            <div className="row center-xs middle-xs">
               <div className="col-xs-12 col-sm-10 col-lg-5 last-xs first-lg">
                  <div className="feature-img wide"><img src="/img/feature-crop-grade-manage.png"
                     alt="feature-crop-grade-manage" /></div>
               </div>
               <div className="col-xs-12 col-sm-10 col-lg-3 col-lg-offset-1">
                  <h3>Student Management</h3>
                  <p className="text-larger">Easily manage students on Lingco Classroom with our course view and
                  individual student profiles. Instructors can bulk invite students via email or share an access
                        code.</p>
               </div>
               <div className="col-xs-12 col-lg-1"></div>
            </div>
         </div>
      </section>
      <section className="feature-column">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10">
                  <div className="row center-xs">
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/synchronize-3.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>LMS Integrations</h4>
                              <p>Lingco Classroom integrates into all major learning management systems, allowing
                              instructors to sync grading and class roster information between the two
                              systems. With the single sign-on, there’s no extra password for students to
                                        remember.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/folder-hierarchy.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Sections</h4>
                              <p>Teach multiple sections of the same course? No problem! Instructors can easily
                              divide students and manage assignments by section on Lingco Classroom. TAs can
                                        also be invited to oversee and grade coursework.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
         <section className="feature-more">
            <div className="container-fluid">
               <div className="row center-xs text-center margin-bottom-xl">
                  <div className="col-xs-12 col-sm-10">
                     <h5 className="grey">Want to see more features? Check these out</h5>
                  </div>
               </div>
               <div className="row center-xs">
                  <div className="col-xs-12 col-md-5 margin-bottom-m-md"><Link to="/platform/engaging-content">
                     <div className="card">
                        <div className="row middle-xs">
                           <div className="col-xs-12 col-md-6 margin-top-l">
                              <h4>Engaging Content</h4>
                              <p>Our platform empowers instructors to create the course that they envision.
                              </p>
                           </div>
                           <div className="feature-more-img content"><img src="/img/feature-more-content.png"
                              alt="feature-more-content" /></div>
                        </div>
                     </div>
                  </Link></div>
                  <div className="col-xs-12 col-md-5"><Link to="/platform/insightful-analytics">
                     <div className="card">
                        <div className="row middle-xs">
                           <div className="col-xs-12 col-md-6 margin-top-l">
                              <h4>Insightful Analytics</h4>
                              <p>Our real-time analytics help you stay on top of your student’s progress.</p>
                           </div>
                           <div className="feature-more-img analytic"><img src="/img/feature-more-analytic.svg"
                              alt="feature-more-analytic" /></div>
                        </div>
                     </div>
                  </Link></div>
               </div>
            </div>
         </section>
      </section>
   </Layout>
);

export default About;
